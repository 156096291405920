import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SessionService } from '../services/session.service';
import { catchError, switchMap } from 'rxjs/operators';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class SessionGuard implements CanActivate {
  constructor(private session: SessionService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const token = localStorage.getItem('token');

    if (!token) {
      this.logoutAndRedirect(state);
      return false;
    } else {
      return this.session.getUser(token).pipe(
        switchMap((userData: User) => {
          this.session.admin.next(userData);
          return of(true);
        }),
        catchError(() => {
          this.logoutAndRedirect(state);
          return of(false);
        })
      );
    }
  }

  private logoutAndRedirect(state) {
    this.session.logout();
    this.router.navigate(['/auth/login'], {
      queryParams: {
        redirect: state.url,
      },
    });
  }
}
