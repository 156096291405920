import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IMessage } from '../../models/message';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  systemMessageSub: BehaviorSubject<IMessage> = new BehaviorSubject(null);

  constructor() {}

  showMessage(message: IMessage) {
    this.systemMessageSub.next(message);
  }
}
