<div class="sc-container">
  <mat-toolbar color="primary" class="example-toolbar">
    <h1 class="example-app-name">Scope Admin</h1>
    <span class="user-info"> {{ (user | async).firstName }} <mat-icon class="logout" (click)="logout()">exit_to_app</mat-icon> </span>
  </mat-toolbar>
  <mat-sidenav-container class="example-sidenav-container">
    <mat-sidenav [mode]="'side'" [opened]="true" [disableClose]="true" #sideNav>
      <mat-nav-list>
        <a mat-list-item [ngClass]="{ active: boxRouteActive }" routerLink="/boxes">
          <mat-icon mat-list-icon>inbox</mat-icon>
          Workspaces</a
        >
        <a mat-list-item [ngClass]="{ active: boxCloneRouteActive }" routerLink="/boxes/clone">
          <mat-icon mat-list-icon>file_copy</mat-icon>
          Clone Workspace</a
        >
        <a mat-list-item routerLinkActive="active" routerLink="/templates">
          <mat-icon mat-list-icon>build</mat-icon>
          Templates</a
        >
        <a mat-list-item routerLinkActive="active" routerLink="/users">
          <mat-icon mat-list-icon>people</mat-icon>
          Users</a
        >
        <a mat-list-item routerLinkActive="active" routerLink="/file-versions">
          <mat-icon mat-list-icon>archive</mat-icon>
          File Versions</a
        >
        <a mat-list-item routerLinkActive="active" routerLink="/system">
          <mat-icon mat-list-icon>settings</mat-icon>
          System</a
        >
        <a mat-list-item routerLinkActive="active" routerLink="/help">
          <mat-icon mat-list-icon>help</mat-icon>
          Help</a
        >
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="admin-content margin-t-20">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
