import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { SessionService } from '../services/session.service';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppInterceptor implements HttpInterceptor {
  constructor(private session: SessionService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!!this.session.token) {
      req = req.clone({
        setParams: { token: this.session.token },
        headers: req.headers.set('Authorization', `Bearer ${this.session.token}`),
      });
    }

    return next.handle(req).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}
