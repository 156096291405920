import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivateComponent } from './private.component';

const routes: Routes = [
  {
    path: '',
    component: PrivateComponent,
    children: [
      {
        path: '',
        redirectTo: 'boxes',
        pathMatch: 'full',
      },
      {
        path: 'templates',
        loadChildren: () => import('../../modules/template/template.module').then((m) => m.TemplateModule),
      },
      {
        path: 'help',
        loadChildren: () => import('../../modules/help/help.module').then((m) => m.HelpModule),
      },
      {
        path: 'boxes',
        loadChildren: () => import('../../modules/box/box.module').then((m) => m.BoxModule),
      },
      {
        path: 'users',
        loadChildren: () => import('../../modules/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'file-versions',
        loadChildren: () => import('../../modules/file-versions/file-versions.module').then((m) => m.FileVersionsModule),
      },
      {
        path: 'system',
        loadChildren: () => import('../../modules/system/system.module').then((m) => m.SystemModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrivateRoutingModule {}
