import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from './providers/guards/logged-in.guard';
import { SessionGuard } from './providers/guards/session.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./layouts/private/private.module').then((m) => m.PrivateModule),
    canActivate: [SessionGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./layouts/public/public.module').then((m) => m.PublicModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: '**',
    loadChildren: () => import('./layouts/error/error.module').then((m) => m.ErrorModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
