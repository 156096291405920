import { Component, OnInit } from '@angular/core';
import { SessionService } from '@app/providers/services/session.service';
import { User } from '@app/models';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss'],
})
export class PrivateComponent implements OnInit {
  user: Observable<User>;

  constructor(private session: SessionService, private router: Router) {}

  ngOnInit() {
    this.user = this.session.admin;
  }

  logout() {
    this.session.logout();
    this.router.navigate(['/auth']);
  }

  get boxRouteActive(): boolean {
    return this.router.isActive('/boxes', true);
  }

  get boxCloneRouteActive(): boolean {
    return this.router.isActive('/boxes/clone', true);
  }
}
