import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User, UserRole } from '../../models/user';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  admin = new BehaviorSubject<User>(null);
  errors = new BehaviorSubject(null);
  token: string | null = null;
  private user: User;

  constructor(private http: HttpClient) {}

  getUser(token: string) {
    this.token = token;
    localStorage.setItem('token', this.token);
    const url = `${environment.API_BASE_URL}/v4/user/me`;

    return this.http
      .get(url, {
        params: new HttpParams().set('token', token),
      })
      .pipe(
        map((userData: User) => {
          this.setUserData(userData);
          if (userData.role === UserRole.ADMIN) {
            return userData;
          } else {
            throw new Error('Not an admin user');
          }
        })
      );
  }

  private setUserData(data: User) {
    this.user = data;
  }

  getUserData() {
    return this.user;
  }

  logout() {
    this.token = null;
    localStorage.removeItem('token');
  }
}
