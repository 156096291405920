import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PrivateModule } from './layouts/private/private.module';
import { PublicModule } from './layouts/public/public.module';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppInterceptor } from './providers/interceptors/app.interceptor';
import { ErrorModule } from './layouts/error/error.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PrivateModule,
    PublicModule,
    ErrorModule,
    LoadingBarHttpClientModule,
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
