import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from './providers/services/message.service';
import { IMessage } from './models/message';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(public snackBar: MatSnackBar, private messageService: MessageService) {}

  ngOnInit() {
    this.messageService.systemMessageSub.pipe(debounceTime(20)).subscribe((message: IMessage) => {
      if (!!message) {
        this.openSnackBar(message.message, message.action, message.severity, message.duration);
      }
    });
  }

  private openSnackBar(message: string, action: string = null, severity: string = 'info-msg', duration: number = 3500) {
    this.snackBar.open(message, action, {
      duration,
      panelClass: severity,
    });
  }
}
